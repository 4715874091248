<template>
  <SingleKavel />
</template>

<script>
import SingleKavel from "@/components/SingleKavel";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    SingleKavel,
  },
  metaInfo: {
    title: "Kavel informatie | Postzegels.com ",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>
