<template>
  <v-container>
    <h4>
      <b>Kavelnummer {{ kavel.kavelnr }}</b>
    </h4>

    <p>
      <b
        >Om deze bestelling te bevestigen
        <span style="color:red" @click="handleAddToCart()"> KLIK HIER </span></b
      >
    </p>

    <div style="margin-top: 5px">
      <v-btn icon color="primary" @click="goBack">
        <v-icon left>mdi-chevron-left</v-icon>
        Terug
      </v-btn>
    </div>
  </v-container>
</template>
<script>
import { mapMutations } from 'vuex';

import apiKavel from "../components/apiKavel";
export default {
  data() {
    return {
      kavel: [],
            selectedKavels: [],
    };
  },
  mounted() {
    apiKavel.getSingleKavel(this.$route.params.id).then((data) => {
      this.kavel = data;
    });
  },
  methods: {
    goBack() {
      window.history.back();
    },
   ...mapMutations(['addToCart']),
    handleAddToCart() {
      this.addToCart(this.kavel);
      this.$router.push('/Winkelwagen');
    },
    
  
  },
};
</script>

<style scoped>
.button {
  color: white;
  margin: 4px;
}
</style>
