<template>
  <PaymentConfirmation />
</template>

<script>
import PaymentConfirmation from "@/components/PaymentConfirmation";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    PaymentConfirmation,
  },
  metaInfo: {
    title: "Betaal bedankt pagina ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
 
  },
};
</script>
