<template>
  <ConfirmOrder />
</template>

<script>
import ConfirmOrder from "@/components/ConfirmOrder";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    ConfirmOrder,
  },
  metaInfo: {
    title: "Postzegels | Bestelling bevestigen ",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>
