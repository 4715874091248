<template>
  <Bestel />
</template>

<script>
import Bestel from "@/components/Footer/Bestel";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Bestel,
  },
  metaInfo: {
    title: "Bestelprocedure Postzegels ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
 
  },
};
</script>
