<template>
  <div>
    <v-container>
      <h1>Bedankt voor uw bestelling!</h1>
      <p>We hebben uw betaling ontvangen en uw bestelling wordt nu verwerkt.</p>
      <p>U ontvangt binnenkort een bevestigingsmail met de details van uw bestelling.</p>
      <p>Als u vragen heeft, neem dan gerust contact met ons op.</p>
    </v-container>
  </div>
</template>

<script>
export default {
  // Voeg eventuele logica toe als dat nodig is
}
</script>

<style scoped>
/* Voeg hier indien nodig aangepaste stijlen toe */
</style>
