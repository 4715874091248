<template>
  <v-container>

    <CoolLightBox :items="items" :index="index" @close="index = null">
    </CoolLightBox>
    <v-row>
      
      <v-col>
        <h5>Kavelnummer: {{ kavel.kavelnr }}</h5>
        <b>Land:</b> {{ kavel.land.Ned }}<br />
        <b>Beschrijving: </b>{{ kavel.txtNed }}<br/>
        <b>Aantal afbeeldingen:</b> {{ images.length }}
      </v-col>

      <v-col>
        <b>Inhoud:</b> {{ kavel.inhoudNL }}<br />
        <b>Emballage:</b> {{ kavel.embNed }}<br />
        <template v-if="kavel.promotionPrice">
          <b>Prijs:</b> {{ kavel.promotionPrice }} <span  class="strike">{{ kavel.price }}</span>
        </template>
        <template v-else>
          <b>Prijs:</b> € {{ kavel.price }}
        </template>
      </v-col>
    </v-row>

    <v-icon color="success" @click="confirmOrder(kavel.id)">
      mdi-cart
    </v-icon>
    <v-row>
      <v-col>
        <div style="margin-top: 5px">
          <v-btn icon color="primary" @click="goBack">
            <v-icon left>mdi-chevron-left</v-icon>
            Terug
          </v-btn>
        </div>
      </v-col>
      <v-btn color="success" @click="confirmOrder(kavel.kavelnr)"
        >Bestel dit kavel
      </v-btn>
    </v-row>

    <v-row>
      <v-col v-for="(item, imgIndex) in images" :key="item.name" cols="12">
        <v-card class="ma-3">
          <v-img
            :src="imageSrc(item.loc)"
            aspect-ratio="1.5"
            @click.stop="index = imgIndex"
          />
          <div style="float: right;">
            <v-icon color="success" @click="confirmOrder(kavel.kavelnr)">
              mdi-cart
            </v-icon>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-icon color="success" @click="confirmOrder(kavel.id)">
      mdi-cart
    </v-icon>
  </v-container>
</template>

<script>
import apiKavel from "../components/apiKavel";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  components: {
    CoolLightBox,
  },
  data() {
    return {
      index: null,
      kavel: [],
      images: [],
    };
  },
  computed: {
  items() {
    return this.images.map((img, index) => ({
      src: this.imageSrc(img.loc),
      title: `Afbeelding ${index + 1} van  ${ this.images.length }`
    }));
  },
},

  mounted() {
    apiKavel.getSingleKavel(this.$route.params.id).then((data) => {
      this.kavel = data;
    });
    this.fetchKavelData();
  },
  methods: {
    fetchKavelData() {
      apiKavel.getSingleKavel(this.$route.params.id).then((data) => {
        this.kavel = data;
        this.fetchImagesForKavel(data.kavelnr);
      });
    },
    fetchImagesForKavel(kavelnr) {
      const folderName = `kavels/${kavelnr}`;
      apiKavel.getDirectoriesFiles(folderName).then((data) => {
        this.images = data;
      });
    },
    imageSrc(loc) {
      return `https://server.postzegels.com/${loc}`;
    },
    confirmOrder(kavelnr) {
      this.$router.push(`/BevestigBestelling/${kavelnr}`);
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style scoped>
.button {
  color: white;
  margin: 4px;
}

.strike {
  text-decoration: line-through
}
</style>
