<template>
  <Algemenevoorwaarden />
</template>

<script>
import Algemenevoorwaarden from "@/components/Footer/Algemenevoorwaarden";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Algemenevoorwaarden,
  },
  metaInfo: {
    title: "Algemenevoorwaarden Postzegels ",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
 
  },
};
</script>
